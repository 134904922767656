@font-face {
    font-family: 'Circular XX';
    src: url('../font/CircularXX-Light.woff') format('woff2'), url('../font/CircularXX-Light.woff2') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular XX';
    src: url('../font/CircularXX-Regular.woff') format('woff2'), url('../font/CircularXX-Regular.woff2') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular XX';
    src: url('../font/CircularXX-Medium.woff') format('woff2'), url('../font/CircularXX-Medium.woff2') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular XX';
    src: url('../font/CircularXX-Bold.woff') format('woff2'), url('../font/CircularXX-Bold.woff2') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing:border-box;
    font-family: 'Circular XX'; 
    color: #10182F;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill:focus {
    box-shadow: 0 0 0 30px #e8f1ff inset !important;
    -webkit-box-shadow: 0 0 0 30px #e8f1ff inset !important;
}

html {
    font-size: 10px;
    background-color: #F9FCFF;
}

html, body, #root {
    height: 100%;
}

#root{
    margin:0 auto;
}

::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.react-datepicker {
    font-size: 16px !important;
}

.react-datepicker__current-month {
    font-size: 16px !important;
}
.react-datepicker-popper {
    z-index: 100 !important;
}

.react-datepicker__header {
    padding-top: 13px !important;
    background-color: #E8F1FF;
}

.react-datepicker__navigation {
    top: 16px !important;
}

.react-datepicker__day-name, .react-datepicker__day {
    margin: 8px !important;
}
